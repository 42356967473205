import "./App.css";
import products from "./products.json";

// extra imageobjs
/*
{
      "desc": "Fully Auto-Matic Geyser",
      "src": "fullyAutomatic.jpg"
    },
    {
      "desc": "Boiler",
      "src": "boiler.jpg"
    },
    {
      "desc": "Hot Water Storage Tank",
      "src": "hotWaterTank.jpg"
    },
    {
      "desc": "Hot Water Heating System",
      "src": "heatingSystem.jpg"
    },
*/

function productSection(product) {
  return (
    <div className="productDiv">
      <h2>{product.title}</h2>
      <div className={"productImages"}>
        {product.images.map((image) => {
          return (
            <a
              href={require(`./assets/images/${image}`)}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt={product.desc}
                src={require(`./assets/images/${image}`)}
              />
            </a>
          );
        })}
      </div>
      <p>{product.desc}</p>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <h1 className="title">
        🔥 Hotwell Industries<span>ISLAMABAD</span>
      </h1>
      <div className="header">
        <h2>Contact us at</h2>
        <a href="tel:+923317504272">
          <h3>+923317504272</h3>
        </a>
        <a href="tel:+923075360068">
          <h3>+923075360068</h3>
        </a>
        <marquee class="text-white" direction="left">
          Up to 80% GAS SAVING WATER HEATERS
        </marquee>
      </div>
      {products.map((product) => {
        console.log(product);
        return productSection(product);
      })}
      {/* <div className="footer">
        <h2>Contact us at</h2>
        <h3>+92 331 750 4272</h3>
        <h3>+92 307 536 0068</h3>
      </div> */}
    </div>
  );
}

export default App;
